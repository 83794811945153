import React, { useEffect, useRef } from 'react';
import { IcoYoutube, IcoFb, IcoWhatsapp } from './UiKit/SocialIcos';
import texts from './texts.json';
import { Link, useNavigate } from 'react-router-dom';

// Function to handle smooth scrolling
const smoothScrollTo = (targetId) => {
  const target = document.getElementById(targetId);
  if (target) {
    // Update the URL hash without jumping
    window.history.pushState(null, null, `#${targetId}`);

    // Scroll to the target smoothly
    target.scrollIntoView({ behavior: 'smooth' });
  }
};













export const Hero = ({ lang = 'en', page = 'home' }) => {
  const leafRef1 = useRef(null);
  const leafRef2 = useRef(null);
  const leafRef3 = useRef(null);
  const txt1 = useRef(null);
  const txt2 = useRef(null);
  const txt3 = useRef(null);
  const picIskon = useRef(null);
  const picParthaSarathi = useRef(null);
  const picPrabhupada = useRef(null);
  const menu = useRef(null);


  const navigate = useNavigate();

  const handleScroll = () => {
    const scrollY = window.scrollY;

    // Adjust the position of the leaves based on scroll
    if (leafRef1.current) {
      leafRef1.current.style.transform = `translateY(${scrollY * 0.1}px)`; // Adjust the multiplier for effect
    }
    if (leafRef2.current) {
      leafRef2.current.style.transform = `translateY(${scrollY * 0.2}px)`; // Adjust the multiplier for effect
    }
    if (leafRef3.current) {
      leafRef3.current.style.transform = `translateY(${scrollY * 0.1}px)`; // Adjust the multiplier for effect
    }
    if (picParthaSarathi.current) {
      // picParthaSarathi.current.style.transform = `translateY(${scrollY * 0.1}px)`; // Adjust the multiplier for effect
    }
    if (picPrabhupada.current) {
      // picPrabhupada.current.style.transform = `translateY(${scrollY * -0.1}px)`; // Adjust the multiplier for effect
    }
    if (picIskon.current) {
    // picIskon.current.style.transform = `translateY(${scrollY * -0.1}px)`; // Adjust the multiplier for effect
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Set initial styles for animation
    const animateElements = () => {
      const elements = [
        picPrabhupada,
        picParthaSarathi,
        picIskon,
        txt1,
        txt2,
        txt3,
        menu,
        leafRef1,
        leafRef2,
        leafRef3,
      ];
      elements.forEach((ref, index) => {
        if (ref.current) {
          ref.current.style.opacity = '0';
          ref.current.style.transform = 'translateY(-20px)';
          ref.current.style.transition = 'opacity 0.5s ease, transform 0.5s ease'; // Add transition
        }
      });

      // Trigger animation after a staggered delay
      elements.forEach((ref, index) => {
        setTimeout(() => {
          if (ref.current) {
            ref.current.style.opacity = '1';
            ref.current.style.transform = 'translateY(0)';
          }
        }, 100 + index * 100); // Stagger by 100ms for each element
      });
    };

    animateElements(); // Call the animation function

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <div className="c0">
      <div className="bg bg--home">
        <div className="x_leafs">
          <div className="leaf-container" ref={leafRef1}>
            <div className="x_leaf_r" />
          </div>
          <div className="leaf-container" ref={leafRef2}>
            <div className="x_leaf_l2" />
          </div>
          <div className="leaf-container" ref={leafRef3}>
            <div className="x_leaf_l" />
          </div>
        </div>
      </div>
      <div className="w">
        <div className="x_psdg_hero">

          <div className="x_iskon00" ref={picIskon}>
            <div className="x_psdg_blob2">
              <div className="x_leafs">
                <div className="x_leaf_r" />
                <div className="x_leaf_l2" />
                <div className="x_leaf_l" />
              </div>
            </div>
            <div className="x_psdg_ico">
              <a href="https://chat.whatsapp.com/F75khArsaHq425999xy59F" target="_blank" className="x_psdg_logo w-inline-block" />
            </div>
          </div>

          <div className="x_prabh0" ref={picPrabhupada}>
            <div className="x_prabh" />
            <div className="x_prabh_title">

              <div className="x_prabh_title_line_mobile">

                <span className="x_prabh_title_h">
                  {texts[lang].prabhupada.title}
                </span> — {texts[lang].prabhupada.subtitle1} <span className="x_prabh_title_iskcon">.</span>{texts[lang].prabhupada.subtitle1_1}

              </div>


              <div className="x_prabh_title_txt">
                <div className="x_prabh_title_line">
                  <div className="x_prabh_title_h">{texts[lang].prabhupada.title}</div>
                  <div>{texts[lang].prabhupada.subtitle1}
                    <a href='https://www.iskcon.org/' target="_blank">
                      <span className="x_prabh_title_iskcon" />
                    <strong>{texts[lang].prabhupada.subtitle1_1}</strong>
                    </a>
                  </div>
                </div>
                <div className="x_prabh_title_line x_prabh_title_line--3">
                  <div>{texts[lang].prabhupada.subtitle2}</div>
                </div>
              </div>
            </div>
          </div>


          <div className="x_psdg_title">
            <div className="x_psdg_pic0">
              <div className="x_psdg_blob" />
              <div className="x_psdg_pic" ref={picParthaSarathi}>
                <div className="x_psdg_flare" />
                <div className="x_psdg_flare x_psdg_flare--1" />
              </div>
            </div>
            <div className="x_psdg_x">
              <div className="x_welcome" ref={txt1}>
                <div>{texts[lang].psdg.welcome}</div>
              </div>
              <div className="x_psdg0" ref={txt2}>
                <div>{texts[lang].psdg.h1}</div>
              </div>
              <div className="x_psdg_story" ref={txt3}>
                {/* {lang === 'en' ? <HeroPsdgEn /> : <HeroPsdgRu />} */}
                <p>{texts[lang].psdg.p1}</p>
                <p>{texts[lang].psdg.p22}<Link to={`/${lang}/about`} className="x_psdg_more_but">{texts[lang].ui.readMore}</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w">
        <div className="x_menu0">


          <div className="x_menu_ul">
            <div className={`x_menu_li ${page === 'home' ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault(); // Prevent default anchor click behavior
                if (lang === 'ru') navigate(`/${lang}`);
                else navigate(`/`);
                setTimeout(() => {
                  smoothScrollTo('videos');
                }, 100);
              }}
            >
              <div className="x_menu_text">{texts[lang].ui.videos}</div>
              <div className="x_leaf_l x_leaf_l--1" />
            </div>
            <div className={`x_menu_li ${page === 'photos' ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/photos`);
                setTimeout(() => {
                  smoothScrollTo('photos');
                }, 100);
              }}
            >
              <div className="x_menu_text">{texts[lang].ui.photos}</div>
              <div className="x_leaf_l2 x_leaf_l2--menu" />
            </div>
            <div className={`x_menu_li ${page === 'books' ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault(); // Prevent default anchor click behavior
                navigate(`/books`);
                setTimeout(() => {
                  smoothScrollTo('books');
                }, 100);
              }}
            >
              <div className="x_menu_text">{texts[lang].ui.books}</div>
              <div className="x_leaf_r x_leaf_r--menu" />
            </div>
          </div>



          <a href="https://chat.whatsapp.com/F75khArsaHq425999xy59F" target="_blank" className="x_menu_psdg0 w-inline-block">
            <div className="x_menu_psdg">
              <div className="x_menu_psdg_cta">
                <div>{texts[lang].ui.join}</div>
              </div>
              <div className="x_menu_psdg_logo" />
              <div className="x_menu_psdg_cta">
                <div>{texts[lang].ui.whatsappGroup}</div>
              </div>
            </div>
            <div className="x_menu_qr">
              <img src="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/673b6859a7b8d37ce4f5a0aa_qrrr.png" loading="lazy" sizes="(max-width: 991px) 100vw, 72px" srcSet="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/673b6859a7b8d37ce4f5a0aa_qrrr-p-500.png 500w, https://cdn.prod.website-files.com/66da39761f404aa0641056e6/673b6859a7b8d37ce4f5a0aa_qrrr.png 706w" className="x_menu_qr_img" />
            </div>
          </a>
          <div className="x_psdg_grad" />
          <div className="x_menu_soc2">
            <div className="x_f_list x_f_list--flat">
              <a href="https://www.youtube.com/@PSDGCONNECT" target="_blank" className="x_f_social0 x_f_social0--flat w-inline-block">
                <div className="x_f_social_ico">
                  <div className="x_f_social_ico w-embed">
                    <IcoYoutube />
                  </div>
                </div>
              </a>
              <a href="https://www.facebook.com/psdgoswami1" target="_blank" className="x_f_social0 x_f_social0--flat w-inline-block">
                <div className="x_f_social_ico x_f_social_ico--flat">
                  <div className="x_f_social_ico w-embed">
                    <IcoFb />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>


      {/* <div className="w" ref={menu}>
        <div className="x_menu0">
          <a
            className="x_menu_item"
            href="#videos"
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor click behavior
              smoothScrollTo('videos'); // Call the smooth scroll function
            }}
          >
            <div>Videos</div>
          </a>
          <a
            className="x_menu_item"
            href="#contact"
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor click behavior
              smoothScrollTo('contact'); // Call the smooth scroll function
            }}
          >
            <div>Contact</div>
          </a>
        </div>
      </div> */}
    </div>
  );
};
